import * as React from 'react';
import {
    Box,
    Flex,
    Heading,
    Button,
    Text
} from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@chakra-ui/icons';

export default function NewsCard(props) {
    return (
        <Flex flexDirection="column" borderRadius="sm" w={{ base: '100%', md: '33.33%', lg: '25%' }} boxShadow="md" key={props.key} p="4">
            <Box mb="4">
                <GatsbyImage image={getImage(props.image)} alt={props.alt} />
            </Box>
            <Box>
                <Heading as="h5" fontSize="1.5rem">
                    {props.title}
                </Heading>
                <Text>
                    {props.date}
                </Text>
                <Text>
                    {props.excerpt}
                </Text>
                <Link to={props.link}>
                    <Button variant="ghost" size="sm">
                        {props.buttonText}{` `}<ChevronRightIcon />
                    </Button>
                </Link>
            </Box>
        </Flex>
    )
}