import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Heading,
  Container,
  Box,
  Flex,
} from '@chakra-ui/react'

import { SliceZone } from '@prismicio/react'
import { components } from "../components/Slices"
import NewsCard from "../components/NewsCard/NewsCard"


const NewsPage = ({ data }) => {
  const doc = data.allPrismicPage?.edges[0].node.data;
  const news = data.allPrismicNewsPost?.edges;

  console.log(news)

  return (
    <Layout>
      <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column" mb="24" mt="12">
            <Box mb="12">
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.page_title.text}
              </Heading>
              <Heading as="h2" variant="h2">
                {doc.page_subtitle.text}
              </Heading>
            </Box>
            {news.length > 0 ? (
              <Flex alignItems="center" justifyContent="center" flexDirection="row" mb="12" w="100%" flexWrap="wrap">
              {news.map((post, index) => (
                <NewsCard 
                  key={index}
                  image={post.node.data.featured_image.localFile}
                  alt={post.node.data.featured_image.alt}
                  title={post.node.data.news_post_title.text}
                  excerpt={post.node.data.news_post_excerpt.text}
                  buttonText="Read More"
                  link={post.node.url}
                  date={post.node.data.posted_date}
                />
              ))}
            </Flex>
            ) : (
              <Heading as="h3" variant="h3" mb="12">
                There is currently no news to report. Please check back soon!
              </Heading>
            )}
          </Flex>
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
query pageQuery {
  allPrismicPage(filter: {uid: {eq: "news"}}) {
    edges {
      node {
        data {
          page_title {
            text
          }
          page_subtitle {
            text
          }
          mobile_hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 800
                  width: 600
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 714
                  width: 1920
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          body {
            ... on PrismicPageDataBodyNewsletterSignup {
              id
              slice_type
              primary {
                newsletter_signup_title {
                  text
                }
                newsletter_signup_subtitle {
                  text
                }
                newsletter_signup_button_label
                newsletter_signup_background_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyCallToAction {
              id
              slice_type
              primary {
                link_label
                link {
                  url
                }
                call_to_action_title {
                  text
                }
                call_to_action_content {
                  richText
                }
                call_to_action_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyDowntownEvents {
              id
              primary {
                justify_image
                button_label
                button_link {
                  url
                }
                event_content {
                  richText
                }
                event_logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                    publicURL
                  }
                  alt
                }
                event_title {
                  text
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
              items {
                event_fact_title {
                  text
                }
                event_fact_stat {
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageDataBodyEventsCarousel {
              id
              primary {
                show_events_carousel
                carousel_title {
                  text
                }
              }
              slice_type
            }
          }
          page_submenu {
            menu_item_link {
              url
            }
            menu_item_label
          }
        }
      }
    }
  }
  allPrismicNewsPost {
    edges {
      node {
        uid
        url
        data {
          posted_date(formatString: "MMMM D, YYYY")
          news_post_title {
            text
          }
          news_post_excerpt {
            text
          }
          featured_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
        }
      }
    }
  }
}
`

export default NewsPage

export const Head = ({ data }) => <title>{data.allPrismicPage.edges[0].node.data.page_title.text}</title>
